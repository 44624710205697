<template>
    <v-snackbar
      v-model="show"
      timeout=2000
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="color"
          text
          v-bind="attrs"
          @click="localShow = false"
        >
          cerrar
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>

export default {
  name: 'MessageToast',
  props: ['message', 'color', 'value'],
  computed: {
    show: {
      get() {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>