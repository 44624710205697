<template>
    <delivery-point-order></delivery-point-order>
</template>

<script>
import DeliveryPointOrder from '@/components/deliverypoints/DeliveryPointOrder.vue'

export default {
    name: 'DeliveryPointOrderView',
    components: { DeliveryPointOrder }
}
</script>